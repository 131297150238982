<template>
  <div class="flex flex-col h-full overflow-hidden">
    <div
      :class="{
        'shadow-panel': withShadow,
      }"
      class="p-5 flex flex-row items-center justify-between sticky top-0 bg-white z-10"
    >
      <span class="text-lg font-semibold text-secondary44">
        {{ $t('Vendor portfolio.Filters') }}
      </span>
      <div class="flex flex-row items-center space-x-4">
        <span class="text-xs font-black text-red-100 cursor-pointer" @click="onResetFiltersClick">
          {{ $t('Vendor portfolio.Reset filters') }}
        </span>
        <CButton class="text-white" size="small" @click="onApplyFiltersClick">
          {{ $t('Vendor portfolio.Apply filters') }}
        </CButton>
      </div>
    </div>

    <div class="flex flex-grow flex-col overflow-hidden">
      <Simplebar class="h-full" @scroll="onFiltersContainerScroll">
        <div v-if="payload" class="px-5 flex flex-col">
          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Software')">
              <div class="flex flex-col space-y-2">
                <FilterSoftwareSearch @select="onSoftSelect" />
                <div v-if="payload.soft.length" class="flex flex-row flex-wrap -mx-1">
                  <div v-for="soft in payload.soft" :key="soft" class="px-1">
                    <div class="flex flex-row items-center space-x-2 py-1 px-2 rounded-4 bg-gray-200">
                      <span class="text-xs text-secondary44">{{ soft }}</span>
                      <div class="w-2 h-2 flex items-center justify-center cursor-pointer" @click="onSoftDelete(soft)">
                        <!-- eslint-disable -->
                        <svg fill="none" height="8" viewBox="0 0 8 8" width="8" xmlns="http://www.w3.org/2000/svg">
                          <path
                            clip-rule="evenodd"
                            d="M4.70644 4.00027L7.34511 6.64132C7.54028 6.83667 7.54014 7.15326 7.34479 7.34843C7.14944 7.5436 6.83286 7.54346 6.63768 7.34811L3.99881 4.70685L1.35353 7.34819C1.15813 7.54331 0.841545 7.54307 0.646428 7.34766C0.451311 7.15226 0.451546 6.83568 0.646954 6.64056L3.29202 3.99942L0.64863 1.35364C0.453457 1.15829 0.4536 0.841705 0.64895 0.646531C0.844301 0.451357 1.16088 0.4515 1.35606 0.646851L3.99965 3.29284L6.63721 0.659197C6.83262 0.46408 7.1492 0.464315 7.34432 0.659722C7.53944 0.855129 7.5392 1.17171 7.34379 1.36683L4.70644 4.00027Z"
                            fill="#92929D"
                            fill-rule="evenodd"
                          />
                        </svg>
                        <!-- eslint-enable -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FilterContainer>
          </div>
          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Directions')">
              <div class="flex flex-col space-y-3">
                <CCheckbox
                  v-for="direction in _directions"
                  :key="direction.value"
                  :value="isDirectionSelected(direction)"
                  @input="onCheckboxSelected('directions', $event, direction)"
                >
                  <span class="text-sm select-none leading-5">
                    {{ direction.label }}
                  </span>
                </CCheckbox>
              </div>
            </FilterContainer>
          </div>
          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Tags')">
              <FilterScroll>
                <div class="flex flex-col space-y-3">
                  <CCheckbox
                    v-for="visual in _visuals"
                    :key="visual.value"
                    :value="isVisualSelected(visual)"
                    @input="onCheckboxSelected('visuals', $event, visual)"
                  >
                    <span class="text-sm select-none leading-5">
                      {{ visual.label }}
                    </span>
                  </CCheckbox>
                </div>
              </FilterScroll>
            </FilterContainer>
          </div>
          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Category')">
              <FilterScroll>
                <div class="flex flex-col space-y-3">
                  <CCheckbox
                    v-for="assetType in _assetTypes"
                    :key="assetType.value"
                    :value="isAssetTypeSelected(assetType)"
                    @input="onCheckboxSelected('assetTypes', $event, assetType)"
                  >
                    <span class="text-sm select-none leading-5">
                      {{ assetType.label }}
                    </span>
                  </CCheckbox>
                </div>
              </FilterScroll>
            </FilterContainer>
          </div>
          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Stages')">
              <FilterScroll>
                <div class="flex flex-col space-y-3">
                  <CCheckbox
                    v-for="stage in _stages"
                    :key="stage.value"
                    :value="isStageSelected(stage)"
                    @input="onCheckboxSelected('stages', $event, stage)"
                  >
                    <span class="text-sm select-none leading-5">
                      {{ stage.label }}
                    </span>
                  </CCheckbox>
                </div>
              </FilterScroll>
            </FilterContainer>
          </div>
          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Complexity level')">
              <div class="flex flex-row items-center space-x-3">
                <CCheckbox
                  v-for="complexity in _complexity"
                  :key="complexity.value"
                  :value="payload.complexity.includes(complexity.value)"
                  name="complexity"
                  @input="onCheckboxSelected('complexity', $event, complexity)"
                >
                  <span class="text-sm leading-5">
                    {{ complexity.label }}
                  </span>
                </CCheckbox>
              </div>
            </FilterContainer>
          </div>
          <!--          <div class="separator" />-->
          <!--          <div class="py-5">-->
          <!--            <FilterContainer :title="$t('Vendor portfolio.Details Lvl')">-->
          <!--              <div class="flex flex-row items-center space-x-3">-->
          <!--                <CRadio-->
          <!--                  v-for="detail in _detail"-->
          <!--                  :key="detail.value"-->
          <!--                  :value="detail.value"-->
          <!--                  name="detail"-->
          <!--                  label-class="items-center"-->
          <!--                  :check-value="payload.detail"-->
          <!--                  @input="payload.detail = $event"-->
          <!--                >-->
          <!--                  <span class="text-sm leading-5">-->
          <!--                    {{ detail.label }}-->
          <!--                  </span>-->
          <!--                </CRadio>-->
          <!--              </div>-->
          <!--            </FilterContainer>-->
          <!--          </div>-->
          <div v-if="!isArtDirector">
            <div class="separator" />
            <div class="py-5">
              <FilterContainer :title="$t('Vendor portfolio.Daily rate, $')">
                <div class="flex flex-row items-stretch -mx-2">
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.rateFrom"
                      :placeholder="$t('Vendor portfolio.Minimal rate')"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                    />
                  </div>
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.rateTo"
                      :placeholder="$t('Vendor portfolio.Maximal rate')"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
              </FilterContainer>
            </div>
          </div>
        </div>
      </Simplebar>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CCheckbox from 'devotedcg-ui-components/CCheckbox.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import { cloneDeep, union } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

import Simplebar from '@/components/Simplebar.vue';
import FilterContainer from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterContainer.vue';
import FilterScroll from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterScroll.vue';
import FilterSoftwareSearch from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterSoftwareSearch.vue';

export default {
  name: 'VendorsPortfolioFilters',
  components: {
    CButton,
    FilterContainer,
    CCheckbox,
    FilterScroll,
    CInput,
    FilterSoftwareSearch,
    Simplebar,
  },
  data() {
    return {
      withShadow: false,
      defaultPayload: {
        directions: [],
        visuals: [],
        assetTypes: [],
        stages: [],
        soft: [],
        complexity: [],
        detail: null,
        rateFrom: '',
        rateTo: '',
      },
      payload: null,
    };
  },
  computed: {
    ...mapGetters({
      getPortfolioFilters: 'vendors/getPortfolioFilters',
    }),
    ...mapState({
      isArtDirector: (state) => state.auth.isArtDirector,
    }),
    _directions() {
      return this.getPortfolioFilters.directions || [];
    },
    _visuals() {
      return this.getPortfolioFilters.visuals || [];
    },
    _assetTypes() {
      return (this.getPortfolioFilters.assetTypes || []).filter(
        (at) =>
          !this.payload?.directions?.length ||
          this.payload?.directions?.includes(at.rootId) ||
          (typeof at.value === 'string' && at.value.includes('|'))
      );
    },
    _stages() {
      return (this.getPortfolioFilters.stages || []).filter(
        (st) =>
          !this.payload?.directions?.length ||
          this.payload?.directions?.includes(st.rootId) ||
          (typeof st.value === 'string' && st.value.includes('|'))
      );
    },
    _complexity() {
      return this.getPortfolioFilters.complexity || [];
    },
    _detail() {
      return this.getPortfolioFilters.detail || [];
    },
  },
  mounted() {
    this.fetchFilters();
    this.payload = cloneDeep(this.defaultPayload);
  },
  methods: {
    ...mapActions({
      fetchFilters: 'vendors/fetchFilters',
    }),
    onFiltersContainerScroll(e) {
      if (e) {
        const { target } = e;
        if (target) {
          const { scrollTop } = target;
          this.withShadow = scrollTop;
        }
      }
    },
    isDirectionSelected(e) {
      return this.payload.directions.includes(e?.value);
    },
    isVisualSelected(e) {
      return this.payload.visuals.includes(e?.value);
    },
    isAssetTypeSelected(e) {
      return this.payload.assetTypes.includes(e?.value);
    },
    isStageSelected(e) {
      return this.payload.stages.includes(e?.value);
    },
    onCheckboxSelected(entity, value, event) {
      if (!this.payload[entity]) {
        return;
      }
      if (value) {
        this.payload[entity] = union(this.payload[entity], [event.value]);
      } else {
        this.payload[entity] = this.payload[entity].filter((d) => d !== event.value);
      }
      this.handleConditionalFilters(entity);
    },
    onResetFiltersClick() {
      this.payload = cloneDeep(this.defaultPayload);
      this.onApplyFiltersClick();
    },
    handleConditionalFilters(entity) {
      if (!this.payload) {
        return;
      }
      if (entity === 'directions') {
        this.$nextTick().then(() => {
          this.payload.assetTypes = this.payload.assetTypes.filter((atValue) =>
            this._assetTypes.find((at) => at.value === atValue)
          );
        });
      }
      if (entity === 'stages') {
        this.$nextTick().then(() => {
          this.payload.stages = this.payload.stages.filter((stageValue) =>
            this._stages.find((stage) => stage.value === stageValue)
          );
        });
      }
    },
    onApplyFiltersClick() {
      const payload = Object.keys(this.payload).reduce((result, key) => {
        if (
          typeof this.payload[key] !== 'undefined' &&
          this.payload[key] !== null &&
          this.payload[key] !== '' &&
          (!Array.isArray(this.payload[key]) || this.payload[key].length)
        ) {
          let data = this.payload[key];
          if (Array.isArray(data)) {
            data = data.join('|');
          }
          return {
            ...result,
            [key]: data,
          };
        }
        return result;
      }, {});
      this.$emit('input', payload);
    },
    onSoftSelect(value) {
      if (value && !this.payload.soft.find((software) => software === value)) {
        this.payload.soft.push(value);
      }
    },
    onSoftDelete(value) {
      if (value) {
        this.payload.soft = this.payload.soft.filter((software) => software !== value);
      }
    },
  },
};
</script>
